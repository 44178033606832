<template>
  <section id="menuList">
    <h1>{{ $t('menu') }}</h1>
    <h5 class="text-primary">
      {{ $t('menuConfg') }}
    </h5>

    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showMenu"
        >
          <b-card lazy>
            <b-card-header>
              <b-card-title>{{ $t('titleMenu') }}</b-card-title>
              <div class="d-flex align-items-center">
                <h5 v-if="nActive >= 5">
                  <b-badge
                    variant="warning"
                    class="mr-2"
                    style="color: black;"
                  >
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="25"
                    />
                    {{ $t('maxMenu') }}
                  </b-badge>
                </h5>

                <b-button
                  class="mr-2"
                  @click="manual = !manual"
                >
                  {{ $t('contents.sort') }}
                </b-button>
                <b-button
                  v-show="nActive < 5"
                  variant="primary"
                  @click="addMenu()"
                >
                  {{ $t('Add') }}
                </b-button>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="ml-2">
                  <b-form-group class="mb-2 mr-1">
                    <b-row v-if="manual">
                      <b-col class="d-flex align-items-center">
                        <b-button
                          variant="outline-warning"
                          disabled
                        >
                          {{ $t("contents.manualSortMode") }}
                        </b-button>
                        <b-button
                          class="ml-2"
                          variant="success"
                          @click="manual = !manual"
                        >
                          {{ $t('contents.finalize') }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="allMenu.length != 0">
                        <b-list-group class="list">
                          <div
                            v-if="manual"
                            class="mt-1"
                          >
                            <b-overlay
                              variant="transparent"
                              :show="showMenu"
                            >
                              <draggable
                                v-model="allMenu"
                                class="list-group list-group-flush cursor-move"
                                tag="ul"
                              >
                                <b-list-group-item
                                  v-for="item in allMenu"
                                  :key="item.node.id"
                                  @dragend="updateOrder"
                                >
                                  <b-row class="align-items-center cursor-pointer">
                                    <b-col md="3">
                                      <i
                                        v-if="item.node.awesomeFontMenu != ''"
                                        :class="'fa-light fa-2x ' + item.node.awesomeFontMenu"
                                      />
                                      <b-img
                                        v-else
                                        lazy
                                        class="base-image-inputIm"
                                        :src="item.node.iconImage"
                                      />
                                    </b-col>
                                    <b-col md="4">
                                      <h5>
                                        {{ item.node.title }}
                                      </h5>
                                    </b-col>
                                    <b-col md="4">
                                      <b-badge
                                        v-if="item.node.isActive"
                                        variant="success"
                                        class="badge"
                                      >
                                        {{ $t('activado') }}
                                      </b-badge>
                                      <b-badge
                                        v-else
                                        variant="danger"
                                        class="badge"
                                      >
                                        {{ $t('desactivado') }}
                                      </b-badge>
                                    </b-col>
                                    <b-col>
                                      <feather-icon
                                        icon="AlignJustifyIcon"
                                        size="18"
                                      />
                                    </b-col>
                                  </b-row>
                                </b-list-group-item>
                              </draggable>
                            </b-overlay>
                          </div>
                          <div v-else>
                            <b-list-group-item
                              v-for="item in allMenu"
                              :key="item.node.id"
                              @click="editMenu(item.node.id)"
                            >
                              <b-row class="align-items-center cursor-pointer">
                                <b-col md="2">
                                  <i
                                    v-if="item.node.awesomeFontMenu != ''"
                                    :class="'fa-light fa-2x ' + item.node.awesomeFontMenu"
                                  />
                                  <b-img
                                    v-else
                                    lazy
                                    class="base-image-inputIm"
                                    :src="item.node.iconImage"
                                  />
                                </b-col>
                                <b-col md="2">
                                  <h5>
                                    {{ item.node.title }}
                                  </h5>
                                </b-col>
                                <b-col md="7">
                                  <b-badge
                                    v-if="item.node.isActive"
                                    variant="success"
                                    class="badge"
                                  >
                                    {{ $t('activado') }}
                                  </b-badge>
                                  <b-badge
                                    v-else
                                    variant="danger"
                                    class="badge"
                                  >
                                    {{ $t('desactivado') }}
                                  </b-badge>
                                </b-col>
                                <b-col>
                                  <feather-icon
                                    icon="Edit2Icon"
                                    class="cursor-pointer text-success mr-2"
                                    size="18"
                                    @click="editMenu(item.node.id)"
                                  />
                                  <feather-icon
                                    icon="Trash2Icon"
                                    class="cursor-pointer text-danger"
                                    size="18"
                                    @click.stop.prevent="deleteMenu(item.node.id)"
                                  />
                                </b-col>
                              </b-row>
                            </b-list-group-item>
                          </div>
                        </b-list-group>
                      </b-col>
                      <b-col v-else>
                        <b-list-group class="list">
                          <b-list-group-item disabled>
                            <b-badge
                              variant="light-primary"
                              class="badge-light cursor-pointer"
                            >
                              <h4>
                                {{ $t('noMenu') }}
                              </h4>
                            </b-badge>
                          </b-list-group-item>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-modal
      ref="menu-view"
      size="lg"
      hide-footer
      :title="$t('Menu')"
    >
      <div>
        <b-tabs
          v-if="languageService && !createBool"
          id="languages"
          v-model="tabIndex"
          pills
          lazy
          vertical
        >
          <b-tab
            v-for="i in locales"
            :key="i.locale"
            class="align-items-start"
            style="align-items: flex-start;"
          >
            <template #title>
              <div class="">
                <b-img
                  :src="i.img"
                  height="14px"
                  width="22px"
                  :alt="i.locale"
                />
                <strong class="ml-50">{{ i.name }}</strong>
              </div>
            </template>

            <menuView
              :id="idMenu"
              :key="i.locale"
              :create-bool="createBool"
              :action="action"
              :language="i.locale"
              :base="base"
              @refresh="getMenu()"
            />
          </b-tab>
        </b-tabs>
        <menuView
          v-else
          :id="idMenu"
          :action="action"
          :language="base"
          :base="base"
          :order-menu="orderMenu"
          :create-bool="createBool"
          @refresh="getMenu()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { messageError, showToast } from '@/store/functions'
import { required } from '@validations'

import {
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BListGroupItem,
  BListGroup,
  BTab, BTabs,
  BButton,
  BFormGroup,
  BCard,
  BImg,
  BBadge,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import menuView from './menuView.vue'

export default {
  components: {
    BOverlay,
    BListGroupItem,
    BListGroup,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BFormGroup,
    BTab,
    BTabs,
    BRow,
    draggable,
    BCol,
    menuView,
    BButton,
    BCard,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      createBool: false,
      menuType: 'CAT',
      menuOptions: [
        { value: 'CAT', text: this.$t('zones.category') },
        { value: 'INT', text: this.$t('dataGeneric.internal') },
        { value: 'URL', text: 'Url' },
      ],
      activeMenu: false,
      action: null,
      title: null,
      order: null,
      userData: getUserData(),
      idMenu: null,
      checkbox: false,
      tabIndex: 0,
      categoryName: null,
      categoryId: null,
      showMenu: false,
      headers: {},
      allMenu: [],
      prefix: null,
      languages: [],
      languageService: true,
      locales: [],
      icon: null,
      orderMenu: 0,
      contentId: null,
      contentFileUrl: null,
      nActive: 0,
      manual: false,
      originalStatus: false,

      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],

      activeCat: false,
      messageDeactive: '',
      activeIcon: true,
    }
  },
  watch: {
    menuType() {
      if (this.menuType !== 'CAT') {
        this.activeCat = true
      } else {
        this.activeCat = this.originalStatus
        this.activeMenu = this.originalStatus
      }
    },

  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    this.getClient()
    this.getMenu()
  },
  methods: {
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    getClient() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                  externalPayMethod
                  currency
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },

    addMenu() {
      this.idMenu = null
      this.createBool = true
      this.orderMenu = this.allMenu.length !== 0
        ? this.allMenu[this.allMenu.length - 1].node.order + 1
        : 0

      this.$refs['menu-view'].show()
    },
    editMenu(id) {
      this.createBool = false
      this.idMenu = id
      this.$refs['menu-view'].show()
    },

    hideModal() {
      this.$refs['menu-view'].hide()
    },
    totalActive() {
      axios
        .post('', {
          query: `
          {
            allMenu(client:"${this.userData.profile.client.id}",isActive:true) {
              totalCount
            }
          }
          `,
        })
        .then(res => {
          messageError(res, this)
          this.nActive = res.data.data.allMenu.totalCount
          this.showMenu = false
        })
    },
    getMenu() {
      this.hideModal()
      this.showMenu = true
      const { headers } = this

      axios
        .post('', {
          query: `
           query{
            allMenu(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  category {        
                    id
                    name
                    imageUrl
                    isActive
                  }
                  key
                  type
                  url
                  order
                  iconImage
                  awesomeFontMenu
                  isActive
                  title
                }
              }
            }
          } 
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.totalActive()
          this.allMenu = res.data.data.allMenu.edges
        })
    },
    deleteMenu(id) {
      this.showMenu = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteMenu(id:"${id}"){
              found
              deletedId
            }
          }
          `,
            })
            .then(res => {
              messageError(res, this)

              this.getMenu()
              showToast(this.$t('success'), 1, this)
            })
            .catch(err => {
              // eslint-disable-next-line
              console.error(err)
              this.showMenu = false

              showToast(this.$t('error'), 2, this)
            })
        } else {
          this.showMenu = false
        }
      })
    },
    updateOrder() {
      this.showMenu = true
      const order = []
      const id = []
      this.allMenu.forEach(element => {
        id.push(element.node.id)
        order.push(element.node.order)
      })

      order.sort((a, b) => a - b)

      const dataArr = new Set(order)

      const result = [...dataArr]
      while (result.length !== order.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = ` mutation
        {`

      for (let i = 0; i < id.length; i += 1) {
        query += `
          m${i + 1}: updateMenu(id:"${id[i]}",input:{ order: ${result[i]}}){
                      menu{
                        id
                        order
                      }
                    }
                  `
      }
      query += `
        }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)
          this.showMenu = false

          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          this.showMenu = false

          showToast(this.$t('error'), 0, this)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#menuList .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#menuList .card-body h4 {
  font-size: 1.286rem !important;
}

#menuList .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#menuList .fly-image-input {
  max-width: 100%;
}

#menuList .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#menuList .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#menuList .div {
  display: inline-block;
}

#menuList #inputColor {
  visibility: hidden;
}

#menuList #backgroundColor {
  visibility: hidden;
}

#menuList .inputColor {
  width: auto;
  color: white;

  background-size: cover;
  background-position: center center;
}

#menuList .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#menuList .placeholder:hover {
  background: #e0e0e0;
}

#menuList .file-input {
  display: none;
}

#menuList .cBox {
  align-items: center;
}

#menuList .custom-control-label {
  font-size: 15px;
}

#menuList .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#menuList .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#menuList .contain {
  object-fit: contain;
  cursor: pointer;
}

#menuList .active-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#menuList .dropContainer {
  border: 3px dashed;
}

#menuList .base-image-inputIm {
  display: block;

  width: 5vh;
  // height: 2vh;
  // margin: auto;
  object-fit: contain;
  -webkit-mask-size: contain;
}
</style>
