<template>
  <section id="menu-view">
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showMenu"
        >
          <b-card
            no-body
            lazy
          >
            <b-card-header class="p-0 mb-2 mt-2">
              <b-card-title>
                {{ $t('menuConf') }}
              </b-card-title>
              <b-button
                class="save-button"
                variant="success"
                @click="validationFormInfo()"
              >
                {{ $t("dataGeneric.save") }}
              </b-button>
            </b-card-header>
            <b-form @submit.prevent="validationFormInfo">
              <validation-observer
                ref="menuRules"
                tag="form"
              >
                <b-row>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="title"
                      rules="required"
                    >
                      <b-form-group :label="$t('title')">
                        <b-form-input
                          id="title"
                          v-model="title"
                          :placeholder="$t('title')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col v-if="language == base">
                    <b-form-group :label="$t('menuTyp')">
                      <b-form-select
                        v-model="menuType"
                        :options="menuOptions"
                        @change="menuChange"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group :label="$t('iconTyp')">
                      <b-form-checkbox
                        v-model="activeIcon"
                        switch
                      >
                        {{ activeIcon ? 'Font-Awesome' : $t('Image') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label>{{ $t('dataGeneric.activeQ') }}
                        <feather-icon
                          v-b-tooltip.hover.top="$t('activeInfo')
                          "
                          class="ml-25"
                          icon="InfoIcon"
                          size="14"
                        />
                      </label>
                      <b-form-checkbox
                        id="activeMenu"
                        v-model="activeMenu"
                        :disabled="activeMenuDB ? false : (!activeCat||deactiveMenu)"
                        :switch="true"
                      >
                        {{ $t('dataGeneric.active') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col v-if="!activeCat || deactiveMenu">
                    <div class="active-info">
                      <p v-if="!activeCat">
                        {{ messageDeactive }}
                      </p>
                      <p v-if="deactiveMenu">
                        {{ $t('maxMenu') }}
                      </p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group
                      v-if="menuType == 'CAT'"
                      :label="$t('catVinc')"
                    >
                      <b-row>
                        <b-col md="10">
                          <validation-provider
                            v-slot="validationContext"
                            name="category"
                            rules="required"
                          >
                            <div @click="$refs['cat-modal'].show()">
                              <b-form-input
                                id="category"
                                v-model="categoryName"
                                :placeholder="$t('modal.category')"
                                autocomplete="new-password"
                                :state="validationContext.errors.length > 0 ? false : null
                                "
                                disabled
                              />
                            </div>
                            <small class="text-danger">{{
                              validationMessage(validationContext)
                            }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col md="2">
                          <b-button
                            variant="info"
                            @click="$refs['cat-modal'].show()"
                          >
                            {{ $t('Select') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-form-group
                      v-if="menuType == 'INT'"
                      :label="$t('idElement')"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="keyMenu"
                        rules="required"
                      >
                        <b-form-select
                          id="labelStatus"
                          v-model="keyMenu"
                          :label="$t('message.tableHeader.status')"
                        >
                          <b-form-select-option
                            v-for="key in Object.keys(keyMenuOptions)"
                            :key="key"
                            :value="key"
                          >
                            {{ keyMenuOptions[key] }}
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{
                          errors[0]
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <b-form-group
                        v-if="menuType == 'URL'"
                        label="URL"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="urlMenu"
                          rules="required|url"
                        >
                          <b-form-input
                            id="urlMenu"
                            v-model="urlMenu"
                            placeholder="https://www.google.com"
                            autocomplete="new-password"
                            :state="errors.length > 0 ? false : null
                            "
                          />
                          <small class="text-danger">{{
                            errors[0]
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="language == base">
                  <b-col>
                    <b-form-group
                      v-if="activeIcon"
                      :label="$t('iconMenu')"
                    >
                      <validation-provider
                        v-slot="validationContext"
                        name="icon"
                        rules="required"
                      >
                        <b-row>
                          <b-col md="10">
                            <b-form-input
                              id="icon"
                              v-model="icon"
                              :placeholder="$t('iconMenu')"
                              autocomplete="new-password"
                              disabled
                              :state="validationContext.errors.length > 0 ? false : null
                              "
                              @click="$refs['iconModal'].show()"
                            />
                          </b-col>
                          <b-col md="2">
                            <b-button
                              variant="info"
                              @click="$refs['iconModal'].show()"
                            >
                              {{ $t('Select') }}
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <b-col>
                            <small class="text-danger">{{
                              validationMessage(validationContext)
                            }}</small>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-col
                              v-if="icon != null"
                              md="1"
                            >
                              <i
                                :class="'fa-light fa-3x ' + icon"
                                @click="$refs['iconModal'].show()"
                              />
                            </b-col>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-else
                    >
                      <b-row class="mt-1">
                        <image-element
                          :image-origin="iconImage"
                          :image-type="'iconImage'"
                          :label="$t('Image')"
                          md-length="6"
                          :label-info="$t('infoImage.menu')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.clear") }}
      </b-button>
    </b-modal>

    <b-modal
      ref="iconModal"
      size="xl"
      hide-footer
      :title="$t('fontAwesomeIcon')"
    >
      <div class="d-block text-center">
        <font-icon-modal @iconFontAwesome="iconFontAwesome" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>

import {
  isEmpty, messageError, showToast,
} from '@/store/functions'
import { required } from '@validations'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup, VBTooltip,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import ImageElement from '@/views/components/ImageElement.vue'
import ContentSelector from '../../common/ContentSelector.vue'
import CategorySelector from '../../common/CategorySelector.vue'
import FontIconModal from '../../common/FontIconModal.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardTitle,
    ContentSelector,
    FontIconModal,
    BFormGroup,
    ValidationProvider,
    BFormSelectOption,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    CategorySelector,
    BFormInput,
    BFormSelect,
    ImageElement,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    createBool: {
      type: Boolean,
      default: false,
    },
    orderMenu: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      iconImage: null,
      keyMenu: 'home',
      keyMenuOptions: {
        home: this.$t('contents.start'),
        search: this.$t('contents.search'),
        epg: 'Epg',
      },
      urlMenu: '',
      required,
      menuType: 'CAT',
      menuOptions: [
        { value: 'CAT', text: this.$t('contents.category') },
        { value: 'INT', text: this.$t('dataGeneric.internal') },
        { value: 'URL', text: 'Url' },
      ],
      activeMenuDB: false,
      activeMenu: false,
      actionMenu: null,
      title: null,
      userData: getUserData(),
      idMenuCat: null,
      checkbox: false,
      categoryName: null,
      categoryId: null,
      options: [
        { value: 'CON', text: 'Media' },
        { value: 'FIL', text: 'File' },
      ],
      showMenu: false,
      headers: {},
      allMenu: [],
      prefix: null,
      icon: null,
      contentId: null,
      contentFileUrl: null,
      manual: false,
      originalStatus: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      activeCat: false,
      messageDeactive: '',
      activeIcon: true,
      nActive: 0,
      deactiveMenu: false,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getMenuById()
  },
  methods: {
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'iconImage':
          this.iconImage = data
          break

        default:
          break
      }
    },
    totalActive() {
      axios
        .post('', {
          query: `
          {
            allMenu(client:"${this.userData.profile.client.id}",isActive:true) {
              totalCount
            }
          }
          `,
        })
        .then(res => {
          messageError(res, this)
          this.nActive = res.data.data.allMenu.totalCount
          if (this.nActive >= 5) {
            this.deactiveMenu = !this.activeMenuDB
          }
        })
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
                  mutation {
                    updateMenu(id: "${this.id}", input:{}) 
                    {
                      menu {
                        id
                        order
                      }
                    }
                  }
                `
            data.append('query', query)
            switch (type) {
              case 'iconImage':
                this.iconImage = null
                data.append('deleteFile', 'icon_image')
                break
              default:
                break
            }

            axios
              .post('', data, config)
              .then(createResponse => {
                messageError(createResponse, this)

                showToast(this.$t('success'), 1, this)
              })
              .catch(res => {
                showToast(this.$t('error'), 0, this)
                this.showFiles = false
                console.log(res)
              })
          }
        })
        .catch(err => { console.log(err) })
    },
    menuChange() {
      switch (this.menuType) {
        case 'INT':
          this.keyMenu = 'home'
          this.activeCat = true
          this.urlMenu = ''
          this.categoryName = null
          this.categoryId = null

          break
        case 'CAT':
          this.keyMenu = ''
          this.activeCat = this.originalStatus
          this.activeMenu = this.originalStatus
          this.urlMenu = ''

          break
        case 'URL':
          this.keyMenu = ''
          this.activeCat = true
          this.categoryName = null
          this.categoryId = null

          break
        default:
          break
      }
    },
    errorImg(e) {
      e.target.src = fileError
    },

    chooseInput(dato) {
      document.getElementById(dato).click()
    },

    SelectContentId(data) {
      this.contentId = data
      this.hideModal()
    },
    SelectContentUrl(data) {
      this.videoPr = data
      this.hideModal()
    },

    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
      this.$refs.iconModal.hide()
    },

    getMenuById() {
      this.showMenu = true
      const { headers } = this
      axios
        .post('', {
          query: `
           query{
            allMenu(id:"${this.id}",client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  category {        
                    id
                    name
                    state
                    imageUrl
                    isActive
                  }
                  key
                  type
                  url
                  order
                  iconImage
                  awesomeFontMenu
                  isActive
                  title(lang:"${this.language}")
                }
              }
            }
          } 
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const item = res.data.data.allMenu.edges[0].node
          let state = true

          if (item.category) {
            this.categoryName = item.category.name
            this.categoryId = item.category.id
            state = item.category.state === 'GRE'
          }

          this.title = item.title

          this.keyMenu = item.key
          this.urlMenu = item.url
          this.menuType = item.type
          this.iconImage = item.iconImage !== '' ? item.iconImage : ''

          if (this.iconImage) {
            this.activeIcon = false
          } else {
            this.activeIcon = true
            this.icon = item.awesomeFontMenu
            this.prefix = !isEmpty(item.awesomeFontMenu) ? this.getPrefix(item.awesomeFontMenu, 2) : null
          }
          this.activeMenu = item.isActive
          this.activeMenuDB = item.isActive

          if (this.menuType === 'CAT') { this.activeCat = state } else this.activeCat = true
          this.originalStatus = this.activeCat
          this.messageDeactive = this.$t('deacCateg')

          this.showMenu = false
          this.totalActive()
        })
    },
    addMenu() {
      this.showMenu = true
      const { headers } = this

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
        mutation{
            createMenu(input:{client:"${this.userData.profile.client.id}",
            ${this.keyMenu ? `,key:"${this.keyMenu}"` : ''}
            ${this.urlMenu ? `,url:"${this.urlMenu}",` : ''}
            ${this.categoryId ? `category:"${this.categoryId}"` : ''}          
            type:${this.menuType},
            order:${this.orderMenu},
            ${this.activeIcon ? `awesomeFontMenu:"${this.icon}"` : ''}
            title:"${this.title}",isActive:${this.activeMenu}}){
              menu{
                id
                order
              }
            }
          }        
          `
      data.append('query', query)
      if (!this.activeIcon) { data.append('icon_image', this.iconImage) }

      axios
        .post('', data, { headers }, config)

        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          this.showMenu = false

          showToast(this.$t('error'), 2, this)
        })
    },
    deleteMenu(id) {
      this.showMenu = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteMenu(id:"${id}"){
              found
              deletedId
            }
          }
          `,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('success'), 1, this)
              this.$emit('refresh')
            })
            .catch(() => {
              this.showMenu = false

              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    updateMenu(id) {
      this.showMenu = true
      const { headers } = this

      const config = {
        timeout: 1000 * 60 * 30,
      }

      const data = new FormData()
      if (this.activeIcon) {
        if (this.iconImage) {
          data.append('deleteFile', 'icon_image')
        }
      } else {
        // Empty icon
        this.icon = ''
        data.append('icon_image', this.iconImage)
      }
      let objectVariables = []
      objectVariables = [
        { title: `title${this.language}`, value: this.title, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'key', value: this.keyMenu, type: 'String' },
          { title: 'url', value: this.urlMenu, type: 'String' },
          { title: 'category', value: this.categoryId, type: 'ID' },
          { title: 'awesomeFontMenu', value: this.icon, type: 'String' },
          { title: 'type', value: this.menuType, type: 'ContentsMenuTypeChoices' },
          { title: 'isActive', value: this.activeMenu, type: 'Boolean' },

        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateMenu(id:"${id}",input:{`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        menu{
                id
                order
              }
        }
      }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(res => {
          // eslint-disable-next-line
          console.log(res)
          this.showMenu = false
          showToast(this.$t('error'), 2, this)
        })
    },
    catMenu() {
      if (this.createBool) { this.addMenu() } else { this.updateMenu(this.id) }
    },

    CategorySelection(data) {
      this.categoryName = data.node.name
      this.categoryId = data.node.id
      const state = data.node.state === 'GRE'

      if (this.menuType === 'CAT') this.activeCat = state
      else this.activeCat = true
      this.originalStatus = this.activeCat

      this.messageDeactive = this.$t('deacCateg')
      this.activeMenu = state ? this.activeMenu : false
      this.hideModal()
    },

    iconFontAwesome(data) {
      const [prefix, icon] = data
      this.prefix = prefix
      this.icon = icon
      this.hideModal()
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },

    getPrefix(data, type = 0) {
      const style = data.split(' ')[0]
      const guion = data.split('-')
      guion.shift()
      const res = guion.join('-')
      let prefijo = null

      switch (type) {
        case 1:
          prefijo = res
          break
        case 2:
          prefijo = style
          break
        default:
          prefijo = [style, res]
          break
      }

      return prefijo
    },
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.menuRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.catMenu()
          }
        })
      })
    },

    validationMessage(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#menu-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#menu-view .card-body h4 {
  font-size: 1.286rem !important;
}

#menu-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#menu-view .fly-image-input {
  max-width: 100%;
}

#menu-view .base-image-input {
  display: block;

  background-size: cover;
  background-position: center center;
}

#menu-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#menu-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#menu-view .base-image-inputIm {
  display: block;

  width: 35vh;
  height: 20vh;
  margin: auto;
  object-fit: contain;
  -webkit-mask-size: contain;
}

#menu-view .div {
  display: inline-block;
}

#menu-view .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#menu-view .placeholder:hover {
  background: #e0e0e0;
}

#menu-view .file-input {
  display: none;
}

#menu-view .custom-control-label {
  font-size: 15px;
}

#menu-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#menu-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#menu-view ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#menu-view .contain {
  object-fit: contain;
  cursor: pointer;
}

#menu-view .active-info {
  display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#menu-view .dropContainer {
  border: 3px dashed;
}
</style>
